import * as React from "react"
import { Link } from "gatsby"
import { Col, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const IndexPage = ({ location }) => {
  const [exclamationPoint, setExclamationPoint] = React.useState("\xa0")
  const [startText, setStartText] = React.useState("\xa0")

  React.useEffect(() => {
    async function typeSentence(sentence, delay = 100) {
      const letters = sentence.split("")
      for (let i = 0; i < letters.length; i++) {
        await waitForMs(delay)
        setStartText(prevState => prevState + letters[i])
      }
      return
    }

    function waitForMs(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    const exclamationPointTimer = setTimeout(() => {
      setExclamationPoint("!")
    }, 1000)
    const startTextTimer = setTimeout(() => {
      setStartText("")
      typeSentence("PRESS START")
    }, 1500)
    return () => {
      clearTimeout(exclamationPointTimer)
      clearTimeout(startTextTimer)
    }
  }, [])

  return (
    <Layout location={location}>
      <Seo title={"Landing Page"} />
      <Row className="h-100 flex-fill">
        <Col className="my-auto">
          <h1
            className="glow position-relative-zindex"
            style={{ fontSize: "80px" }}
          >
            {exclamationPoint}
          </h1>
          <Hero />
          <Link to="/home/" className="gamer-text position-relative-zindex">
            {startText}
          </Link>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage
